export default {
  data: function data() {
    return {
      list: [{
        c: "充分利用RFID自动采集、无线通讯、远程传输的功能，实现资产变动信息与系统信息的实时一致，实现由后台系统对工作流程进行有效的实时监控和记录，使管理人员在办公室内就可以及时了解到资产的调拨和使用情况。"
      }, {
        c: "所有资产数据一次性输入，系统根据不同区域的RFID设备，采集数据，自动判断资产位置和轨迹。"
      }, {
        c: "通过可视化的查询界面监控资产状态、位置等，有效利用闲置资产，及时发现故障资产，实时了解资产情况。同时还可通过借用、维护记录进行数据运算实时进行超期预警、维护预警、低库存预警等。"
      }]
    };
  },
  mounted: function mounted() {
    var domBox = this.$refs.box;
    var domText = this.$refs.text;
    $(window).on("scroll", function () {
      var scrollTop = document.documentElement.scrollTop | document.body.scrollTop;
      var domBoxTop = $(domBox).offset().top;
      var y = scrollTop - domBoxTop + $(window).height();

      if (y > 0 && y < $(window).height()) {
        $(domText).css({
          transform: "translateX(".concat(y / 800, "rem)")
        });
      }
    });
  },
  unmounted: function unmounted() {
    $(window).unbind();
  }
};