export default {
  data: function data() {
    return {
      leftList: [{
        icon: require("@/assets/fixed-assets/b/icon1.png"),
        t: "寿命长,可重复使用",
        c: "RFID标签免维护、寿命长、不怕污染，并可重复使用。"
      }, {
        icon: require("@/assets/fixed-assets/b/icon2.png"),
        t: "识别速度快",
        c: "RFID识别快、批量识别、距离远，可以实现快速盘点与统计。"
      }, {
        icon: require("@/assets/fixed-assets/b/icon3.png"),
        t: "资产状况随时掌握",
        c: "实时掌握设备资产情况，可以合理配置资产，实现对设备资产追踪。"
      }, {
        icon: require("@/assets/fixed-assets/b/icon4.png"),
        t: "提高数据准确性",
        c: "采用手持终端盘点，导出资产盘点报表，全方位提高数据准确性及易操作性。"
      }]
    };
  }
};