import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-50560ccd"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "fixed-assets-d"
};
var _hoisted_2 = {
  class: "t wow animate__fadeInDown"
};
var _hoisted_3 = {
  class: "c wow animate__fadeInDown"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createTextVNode(_toDisplayString($props.d.t) + "-", 1), _createElementVNode("text", null, _toDisplayString($props.d.t1), 1)]), _createElementVNode("div", _hoisted_3, _toDisplayString($props.d.c), 1)]);
}