export default {
  data: function data() {
    return {
      image: require("@/assets/fixed-assets/a/1.png"),
      list: [{
        icon: require("@/assets/fixed-assets/a/icon1.png"),
        t: "盘点周期长、效率低",
        c: "资产盘点是资产管理工作中不可缺少的重要环节，它起到了对资产实物帐面核对和反映资产状态的作用，对工作起到了现场校验、监督的作用。大部分企业在资产盘点工作上目前还是完全依靠人工来进行盘点，管理手段滞后，盘点效率、正确率较低，进行了大量的重复劳动，后期数据统计和报表制作工作量大，整个盘点周期长。",
        image: require("@/assets/fixed-assets/a/1.png"),
        imgAlt: '资产管理系统'
      }, {
        icon: require("@/assets/fixed-assets/a/icon2.png"),
        t: "资产分散、责任制未完善，难以管理",
        c: "资产数量庞大、地域分散，信息的不准确、不及时，很难及时了解到资产状态：如数量、组成组件、存放地点、使用部门、责任人、使用状态等。在使用资产时，只是为使用而使用，并不关心这资产由谁负责保管、对自己负责保管的资产的实际情况也并不清楚。这些情况直接导致资产利用率低，极易出现重复购置、资产流失等问题。",
        image: require("@/assets/fixed-assets/a/2.png"),
        imgAlt: '资产管理系统'
      }, {
        icon: require("@/assets/fixed-assets/a/icon3.png"),
        t: "账务跟实物不一致",
        c: "资产种类和数量繁多，使用位置随时变化，资产经常”消失”。传统手工记账往往效率低，工作量大，得到的数据不准确，很容易造成账实不符的情况，管理人员、责任人员不能够实时的查询到资产当前实际状态,无法快速进行预算审批、业务审批、领导决策等工作。",
        image: require("@/assets/fixed-assets/a/3.png"),
        imgAlt: '资产管理系统'
      }]
    };
  }
};