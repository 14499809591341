export default {
  data: function data() {
    return {
      list: [{
        icon: require("@/assets/fixed-assets/g/icon1.png"),
        t: "信息采集",
        c: "工作人员对所有指定资产配上一个RFID电子标签，同时将资产信息写入RFID电子标签，并将信息通过相应的软件存入服务器以作识别之用。"
      }, {
        icon: require("@/assets/fixed-assets/g/icon2.png"),
        t: "资产盘点",
        c: "盘点资产时，只需持手持式RFID阅读器就可读取到资产上的RFID电子标签信息，同时通过通讯接口将所读取到的信息传给服务器，服务器通过应用软件在对这些信息进行相应的处理。"
      }, {
        icon: require("@/assets/fixed-assets/g/icon3.png"),
        t: "资产转移",
        c: "在每栋大楼的关键出入口布置一台固定式RFID读写器，当资产被带离大楼时，RFID资产管理系统会查验调拨记录，如果未经授权调拨资产，报警器提示管理员核查，如果是经授权调拨资产，系统会自动记录出行时间，并对资产的数量进行便捷的统计。"
      }, {
        icon: require("@/assets/fixed-assets/g/icon4.png"),
        t: "资产轨迹",
        c: "根据现场情况，合理布置2.4G阅读器，监控资产轨迹，实时了解资产位置，并可对非法移动的资产报警。"
      }]
    };
  }
};