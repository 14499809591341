export default {
  data: function data() {
    return {
      list: [{
        img: require("@/assets/fixed-assets/e/1.png"),
        t: "智慧基建",
        c: ["基础设施布建，使用RFID技术作为基建管理和数据采集方式。通过RFID电子标签和RFID阅读器的搭配，在门禁管理、资产盘点、资产进出等方面，自动化采集信息并上传数据到后台，减少人工操作，提高管理效率。"]
      }, {
        img: require("@/assets/fixed-assets/e/2.png"),
        t: "智慧工厂",
        c: ["在工厂生产过程中，使用RFID管理原材料、半成品、成品的流转，以及实现生产工序的全/半自动数据采集，以便了解生产进度，为生产管理提供数据支撑"]
      }, {
        img: require("@/assets/fixed-assets/e/3.png"),
        t: "智慧消防",
        c: ["运用 RFID 技术的消防器材固定资产管理系统，通过在消防器材上安装 RFID 电子标签，在后台绑定对应的资产信息，在关键节点和流程，通过对 RFID 电子标签信息的采集，来精确获取到资产的详细信息。从而实现管理器材的快速盘点以及出入库管理， 提高效率的同时避免盘点漏缺情况的发生。"]
      }, {
        img: require("@/assets/fixed-assets/e/4.png"),
        t: "智慧银行",
        c: ["运用 RFID 技术的银行固定资产管理系统，通过在固定上安装 RFID 电子标签，在后台绑定对应的资产信息，在关键节点和流程，通过对 RFID 电子标签信息的采集，来精确获取到资产的详细信息。从而实现管理资产的快速盘点以及出入库管理， 提高效率的同时避免盘点漏缺情况的发生。"]
      }]
    };
  }
};