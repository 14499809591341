export default {
  data: function data() {
    return {
      current: 0,
      nav: [{
        title: "登录系统",
        list: [{
          t: "登录方式",
          image: require("@/assets/fixed-assets/f/login/1.png")
        }, {
          t: "首页功能展示",
          image: require("@/assets/fixed-assets/f/login/2.png")
        }]
      }, {
        title: "系统操作",
        list: [{
          t: "编辑信息",
          image: require("@/assets/fixed-assets/f/operate/1.png")
        }, {
          t: "搜索功能",
          image: require("@/assets/fixed-assets/f/operate/2.png")
        }, {
          t: "数据导出",
          image: require("@/assets/fixed-assets/f/operate/3.png")
        }]
      }, {
        title: "系统管理",
        list: [{
          t: "角色管理",
          image: require("@/assets/fixed-assets/f/manage/1.png")
        }, {
          t: "用户管理",
          image: require("@/assets/fixed-assets/f/manage/2.png")
        }, {
          t: "位置管理",
          image: require("@/assets/fixed-assets/f/manage/3.png")
        }]
      }, {
        title: "资产相关",
        list: [{
          t: "资产类型管理",
          image: require("@/assets/fixed-assets/f/assets/1.png")
        }, {
          t: "资产信息列表",
          image: require("@/assets/fixed-assets/f/assets/2.png")
        }, {
          t: "资产管理位置",
          image: require("@/assets/fixed-assets/f/assets/3.png")
        }]
      }, {
        title: "日常管理",
        list: [{
          t: "资产变更管理",
          image: require("@/assets/fixed-assets/f/daily/1.png")
        }, {
          t: "资产维护管理",
          image: require("@/assets/fixed-assets/f/daily/2.png")
        }, {
          t: "资产盘点管理",
          image: require("@/assets/fixed-assets/f/daily/3.png")
        }]
      }, {
        title: "查询报表",
        list: [{
          t: "资产预警信息",
          image: require("@/assets/fixed-assets/f/form/1.png")
        }, {
          t: "资产盘点报表",
          image: require("@/assets/fixed-assets/f/form/2.png")
        }, {
          t: "资产维护报表",
          image: require("@/assets/fixed-assets/f/form/3.png")
        }]
      }]
    };
  },
  computed: {
    list: function list() {
      var current = this.current,
          nav = this.nav;
      return nav[current].list || [];
    }
  }
};